import React, { useState } from 'react'
import { IAcceptAll, IApiCallStatus, ISelectOption } from '../../../../common/interfaces'
import { Link, useHistory } from 'react-router-dom'
import InputCtrl from '../../../../controls/InputCtrl';
import { useForm } from 'react-hook-form';
import SelectCtrl from '../../../../controls/SelectCtrl';
import { PostData } from '../../../../common/apiServices';
import useToast from '../../../../common/hooks/useToast';

const SignUpUser: React.FC<IAcceptAll> = props => {
    const history = useHistory();
    const [passwordError, setPasswordError] = useState(false);

    const [ApiStaus, SetApiStatus] = useState<IApiCallStatus>({
        InProgress: false,
        Failed: false,
        FailMessage: ""
    });
    const [termsError, setTermsError] = useState(false);
    const {showToast} = useToast()

    const { control, handleSubmit, errors} = useForm<any>({
        // defaultValues: initialValues,
        mode: "all" // when the you blur... check for errors
    });
    const genderOptions: ISelectOption[] = [
        {
            label: "Male",
            value: "male",
        },
        {
            label: "Female",
            value: "female",
        },
    ];

    const showError = (_fieldName: string) => {
        let error = (errors as any)[_fieldName];
        return error ? (
            <div className="error-block">{error.message || "Field Is Required"}</div>
        ) : null;
    };

    const [inputIndex, setInputIndex] = useState(1);

    const onSubmit = async (data: any) => {
        if (data.password !== data.cPassword) {
            setPasswordError(true);
            return
        } else {
            setPasswordError(false);
        }
        console.log(data, "data");
        SetApiStatus({
            ...ApiStaus,
            InProgress: true,
        })
        const payload = {
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password,
            roleId: 2,
            email: data.email,
            gender: data.gender,
            dob: data.dob,
            companyName: data.companyName,
            companyAddress: data.companyAddress,
            companyWebsite: data.companyWebsite,
            phoneNumber: data?.phoneNumber
        }
        try {

            const saveUser = await PostData('User/Save', { ...payload });
            if (saveUser.data && saveUser?.status ===200) {
                history.push("/login");
                showToast("success", saveUser.message)
            } else {
                SetApiStatus({
                    ...ApiStaus,
                    InProgress: false,
                    Failed: true,
                    FailMessage: saveUser.message
                })
                showToast("error", saveUser.message)
            }
        } catch (error) {
            console.log("error", error);

        }
    };

    return (
        <div className='login '>
            {/* <div className=""> */}
            <div className="row align-items-center">
                <div className="col-md-7">
                    <img
                        src="/images/QUARANTINE_5_-_LANDING_PAGE_ILUSTRASI_7_png (1).png"
                        alt="logo"
                    />
                </div>
                <div className="col-md-5">
                    <div className="">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="login">
                                <div className="container">
                                    <div className="row">
                                        <div className="logo text-center">
                                            <Link to="/">
                                                <img src="/images/logo.svg" alt="logo" />
                                            </Link>
                                        </div>
                                        <h3>Sign Up</h3>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <h5 className='fs-5'>Don't have an account. Create an account. It takes less than a minute!</h5>
                                            </div>
                                        </div>
                                        <InputCtrl
                                            control={control}
                                            showError={showError}
                                            type="text"
                                            placeholder="First Name"
                                            name="firstName"
                                            required={true}
                                            className="form-control"
                                            componentName="signup-survey"
                                            disabled={ApiStaus.InProgress}
                                            float={true}
                                            id="firstName"
                                            onInput={(e: any) => {
                                                let inputValue = e.target.value;
                                                // Ensure the first character is a letter and the rest are digits, maximum length 10
                                                inputValue = inputValue.replace(/[^A-Za-z]/g, '');
                                                e.target.value = inputValue;
                                            }}
                                        />
                                        <InputCtrl
                                            control={control}
                                            showError={showError}
                                            type="text"
                                            placeholder="Last Name"
                                            name="lastName"
                                            required={true}
                                            float={true}
                                            className="form-control"
                                            componentName="signup-survey"
                                            disabled={ApiStaus.InProgress}
                                            onInput={(e: any) => {
                                                let inputValue = e.target.value;
                                                // Ensure the first character is a letter and the rest are digits, maximum length 10
                                                inputValue = inputValue.replace(/[^A-Za-z]/g, '');
                                                e.target.value = inputValue;
                                            }}
                                        />
                                        <InputCtrl
                                            control={control}
                                            showError={showError}
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            float={true}
                                            required={true}
                                            className="form-control"
                                            componentName="login"
                                            disabled={ApiStaus.InProgress}
                                        />
                                        <div className="row">
                                            <div className="col-md-6 same-line-controller">
                                                <InputCtrl
                                                    control={control}
                                                    showError={showError}
                                                    type="text"
                                                    placeholder="Company Name"
                                                    name="companyName"
                                                    required={true}
                                                    float={true}
                                                    className="form-control"
                                                    componentName="login"
                                                    disabled={ApiStaus.InProgress}
                                                />

                                                <InputCtrl
                                                    control={control}
                                                    showError={showError}
                                                    type="text"
                                                    placeholder="Company Website"
                                                    name="companyWebsite"
                                                    required={true}
                                                    float={true}
                                                    className="form-control"
                                                    componentName="login"
                                                    disabled={ApiStaus.InProgress}
                                                />
                                            </div>
                                        </div>

                                        <InputCtrl
                                            control={control}
                                            showError={showError}
                                            type="text"
                                            placeholder="Company Address"
                                            name="companyAddress"
                                            required={true}
                                            float={true}
                                            className="form-control"
                                            componentName="login"
                                            disabled={ApiStaus.InProgress}
                                        />

                                        <InputCtrl
                                            control={control}
                                            showError={showError}
                                            type="text"
                                            placeholder="Company Ref Contact Number"
                                            name="phoneNumber"
                                            required={true}
                                            float={true}
                                            className="form-control"
                                            componentName="login"
                                            maxLength={12}
                                            disabled={ApiStaus.InProgress}
                                            onInput={(e: any) => {
                                                let inputValue = e.target.value;
                                                inputValue = inputValue.toUpperCase();
                                                // Ensure the first character is a letter and the rest are digits, maximum length 10
                                                inputValue = inputValue.replace(/[^+0-9]/g, '').substring(0, 12);
                                                e.target.value = inputValue;
                                            }}

                                        />
                                        <div className="controller-outer">
                                            <InputCtrl
                                                showError={showError}
                                                control={control}
                                                type="password"
                                                required={true}
                                                float={true}
                                                placeholder="Password"
                                                name="password"
                                                className="form-control"
                                                disabled={ApiStaus.InProgress}
                                            />
                                            {/* {showError && showError ? showError("password") : null} */}
                                        </div>
                                        <div className="">
                                            <InputCtrl
                                                control={control}
                                                showError={showError}
                                                type="password"
                                                placeholder="Confirm Password"
                                                name="cPassword"
                                                required={true}
                                                float={true}
                                                className="form-control"
                                                componentName="login"
                                                disabled={ApiStaus.InProgress}
                                            />
                                            {/* {showError && showError.cPassword ? showError("cPassword") : null} */}
                                            <div className="text-red">
                                                {passwordError ? (
                                                    <div className="d-block invalid-feedback">
                                                        Password & Confirm Password must match
                                                    </div>
                                                ) : null}
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 same-line-controller d-flex align-items-end">
                                                <InputCtrl
                                                    control={control}
                                                    showError={showError}
                                                    type="date"
                                                    placeholder="Date of Birth"
                                                    name="dob"
                                                    required={true}
                                                    float={true}
                                                    className="form-control"
                                                    componentName="login"
                                                    disabled={ApiStaus.InProgress}
                                                />
                                                <SelectCtrl
                                                    control={control}
                                                    showError={showError}
                                                    options={genderOptions}
                                                    placeholder="Select Gender"
                                                    name="gender"
                                                    required={true}
                                                    // float={true}
                                                    className="form-control"
                                                    disabled={ApiStaus.InProgress}
                                                />
                                            </div>
                                        </div>

                                        <div className="terms">
                                            <input
                                                type="checkbox"
                                                id="terms"
                                                onChange={(e) => {
                                                    setTermsError(!e.target.checked);
                                                }}
                                            />
                                            <label htmlFor="terms">
                                                I hearby accept the Terms and Conditions for the service
                                            </label>
                                        </div>

                                        {ApiStaus.InProgress || ApiStaus.Failed ? (
                                            <div className="form-check">
                                                <label className="text-danger">
                                                    {ApiStaus.InProgress
                                                        ? "Please wait..."
                                                        : ApiStaus.FailMessage}
                                                </label>
                                            </div>
                                        ) : null}
                                        {termsError ? (
                                            <label className="text-danger">
                                                Please accept the terms and conditions
                                            </label>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {/* <div className={inputIndex === 1 ? 'signup-animation signup-animation-center' : 'signup-animation  signup-animation-left'}></div> */}
                            <div className='d-flex justify-content-between align-items-center'>

                                <button type="submit" className={inputIndex === 3 ? 'pink-btn complete' : 'pink-btn'} disabled={ApiStaus.InProgress}>Next</button>
                                <div className='d-flex '>
                                    <label className="already-account w-100">
                                        Already have an account?
                                        <Link to="/login">
                                            <a> Sign in</a>
                                        </Link>
                                    </label>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            {/* </div> */}
        </div>
    )
}

export default SignUpUser

