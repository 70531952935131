import React from 'react'
import { IAcceptAll } from '../../../../common/interfaces'

const SignUpChooser: React.FC<IAcceptAll> = props => {

    return (
        <div className="container">

            <div className="signup-content">
                <h2>Sign Up</h2>
                <div className="row">
                   
                    <div className="col-md-6">
                        <div className="signup-man-image user-image">
                            <img src="/images/business-man 3.png" />
                        </div>
                        <h4>USER</h4>
                        <button className="signup-btn user-signup-btn" onClick={() => props.history.push('/signup/user')}>
                            Signup
                            </button>
                    </div>
                    <div className="col-md-6">
                        <div className="signup-man-image client-image">
                            <img src="/images/colleagues 2.png" />
                        </div>
                        <h4>CLIENT</h4>
                        <button className="signup-btn client-signup-btn" onClick={() => props.history.push('/client/signup')}>
                            Signup
                            </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpChooser

